<template>
  <div class="LogOut"/>
</template>

<script>
import { auth } from '@/firebase/init'

export default {
  name: 'LogOut',
  mounted() {
    this.$store.dispatch('resetOnLogOut')
    requestAnimationFrame(() => {
      auth.signOut()

      requestAnimationFrame(() => {
        this.$router.push({ name: 'SignIn', query: { ...this.$route.query } })
      })
    })
  },
}
</script>

<style lang="stylus" scoped>
  .LogOut
    display block
</style>
